
/* ############################################################################################## */
/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/*                                              Config                                            */
/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* Shop-Config */
import shopConfig from 'template/config'

/* Types */
import { PayPalScriptOptions } from '@paypal/paypal-js/types/script-options'

const dev = (process.env.NODE_ENV === 'development')

const paypalConfig: PayPalScriptOptions = {
  ...shopConfig.paypal[dev ? 'dev' : 'live'],
  debug: false, // dev,
  locale: 'de_DE',
  currency: 'EUR',
  vault: false,
  commit: true,
  components: 'buttons,marks,messages',
  ['integration-date']: '2022-08-16',
} as const

export default {

  dev,

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /*                                  Shop                                 */
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  mwst: 19,

  mindermengenzuschlag: {
    entfaelltAb: 49,
    betrag: 5,
  },

  versandkosten: {
    deutschland: 0,
    dhlZohne1: 20,
    dhlZohne2: 25,
    dhlZohne3: 30,
    ausserhalbEuropa: 50,
  },

  stockDeliveryTime: 3, // days

  shop: {
    shop: Number(process.env.NEXT_PUBLIC_SHOP_ID),
    lang: process.env.NEXT_PUBLIC_LANGUAGE,
  },

  couponDisplay: shopConfig.couponDisplay,

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /*                           Zahlungsvarianten                           */
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

    VorkasseDisplay: shopConfig.VorkasseDisplay,

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  
  onlyStockWarningLimit: 10,
  stockFullQty: 10,

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /*                               API-Server                              */
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  // Der API-Server
  api_server: {
    local: 'http://shops-api:50001',
    online: !dev
      ? `https://${process.env.NEXT_PUBLIC_HOST}/api`
      : 'http://shops-api:50001'
  },

  // Image-Server
  image_server: !dev
    ? `https://${process.env.NEXT_PUBLIC_HOST}/image`
    : 'http://shops-image:50002'
  ,

  /*
  // Price-Spy-Server
    price_spy: !dev
    ? 'https://shops.homeylove.de/price-spy'
    : 'http://shops-price-spy:51003'
  ,
  */

  googleRecaptchaKey: '6LfM41klAAAAAIGni4UIvJnM65mm0L8YfQT_PFMc',

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /*                                Caching                                */
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  cache_ssr_products_size:    1000,      // Tausend
  cache_static_products_size: 1_000_000, // Eine Millon

  cache_ssr_menues_size:    100,   // Hundert
  cache_static_menues_size: 1_000, // Tausend

  cache_ssr_brands_size:    100,   // Hundert
  cache_static_brands_size: 1_000, // Tausend

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /*                             Datum-Formate                             */
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  dateShort: {
    year: 'numeric', month: '2-digit', day: '2-digit'
  },

  dateLong: {
    year: 'numeric', month: 'long', day: '2-digit'
  },

  dateLongWeekday: {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
  },

  onlyTime: {
    hour: '2-digit', minute: '2-digit', second: '2-digit'
  },

  dayTime: {
    month: '2-digit', day: '2-digit',
    hour: '2-digit', minute: '2-digit'
  },

  dateTime: {
    year: 'numeric', month: '2-digit', day: '2-digit',
    hour: '2-digit', minute: '2-digit'
  },

  dateTimeMiddle: {
    weekday: 'long', year: 'numeric', month: '2-digit', day: 'numeric',
    hour: '2-digit', minute: '2-digit'
  },

  dateTimeLong: {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit'
  },

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /*                         Zahlen-Formate (Numeral)                      */
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  Numeral: {
    delimiters: {
      thousands: '.',
      decimal: ','
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
    },
    currency: {
      symbol: '€'
    }
  },


  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  /*                                 PayPal                                */
  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  paypalConfig,

  /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

} as const

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* ############################################################################################## */
