import { tItem } from 'src/shared/frontendTypes/warenkorb'

import clone from 'src/functions/cloner'

type items = { [column: string]: tItem }

const items: items = {}

const initialState = {
  timestamp: <string|false>false,
  items,
  coupon: null,
}

export type tWarenkorb = typeof initialState

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

export type action =
  | {
      type: 'restoreWarenkorbItems'
      payload: items
    }
  | {
      type: 'addWarenkorbItem'
      payload: tItem
    }
  | {
      type: 'removeWarenkorbItem'
      payload: string
    }
  | {
      type: 'qtyWarenkorbItem'
      payload: { 
        key: string
        qty: number
      }
    }
  | {
      type: 'clearWarenkorb'
      payload?: undefined
    }
  | {
      type: 'applyCoupon'
      payload: string // Aktion für den Coupon
    }

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

const save = (state: tWarenkorb) => {
  setTimeout(()=>{
    localStorage.setItem('warenkorb', JSON.stringify(state))
  }, 0)
  return { ...state, timestamp: new Date().toISOString() }
}

const reducer = (state = initialState, {type,payload}: action): tWarenkorb => {
  switch (type) {

    /* -------------------------------------------------------------- */

    case 'restoreWarenkorbItems': {
      return save({ ...state,
        items: payload,
        timestamp: new Date().toISOString(),
      })
    }

    /* -------------------------------------------------------------- */

    case 'addWarenkorbItem': {
      const items = clone(state.items)
      const item = payload
      const { key, qty } = item

      if (!items[key]) items[key] = item
      else             items[key].qty += qty

      return save({ ...state,
        items,
      })
    }

    /* -------------------------------------------------------------- */

    case 'removeWarenkorbItem': {
      const items = clone(state.items)
      delete items[payload]
      return save({ ...state,
        items,
      })
    }

    /* -------------------------------------------------------------- */

    case 'qtyWarenkorbItem': {
      const items = clone(state.items)
      const { key, qty } = payload

      if (qty > 0)
        items[key].qty = qty
      else
        delete items[key]

      return save({ ...state,
        items,
      })
    }
    
    /* -------------------------------------------------------------- */

    case 'applyCoupon': {
      return save({
        ...state,
        coupon: payload, // Coupon zum Zustand hinzufügen
      })
    }
    /* -------------------------------------------------------------- */

    /*case 'clearWarenkorb': {
      return save({ ...state,
        items: {},
      })
    }*/

    /* -------------------------------------------------------------- */

    default: return state

    /* -------------------------------------------------------------- */
    
  }
}

export default reducer