/* React */
import { useEffect } from 'react'

/* Redux */
import { useDispatch } from 'src/redux/hooks'

/* Types */
import { hasOwnProperty } from 'src/functions/typeGuards'
import { 
  isItem as isWarenkorbItem,
  isItems as isWarenkorbItems,
} from 'src/shared/frontendTypes/warenkorb'
import {
  isStorageKasse
} from 'src/shared/frontendTypes/kasse'
import { 
  isItem as isWunschlisteItem,
  isItems as isWunschlisteItems,
} from 'src/shared/frontendTypes/wunschliste'

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const CheckLocalStorage = () => {
  const dispatch = useDispatch()

  const loadUser = () => {
    const storedUser = localStorage.getItem('user')
    if (!storedUser) { dispatch({ type: 'logout' }); return }
    const user = JSON.parse(storedUser)
    dispatch({ type: 'login', payload: user })
  }

  const loadWarenkorb = () => {
    const storedWarenkorb = localStorage.getItem('warenkorb')
    if (!storedWarenkorb) return
    const warenkorb:unknown = JSON.parse(storedWarenkorb)
    if (
      !warenkorb
      || typeof warenkorb !== 'object'
      || !hasOwnProperty(warenkorb, 'items')
      || !isWarenkorbItems(warenkorb.items)
      || !Object.values(warenkorb.items).every(item=>isWarenkorbItem(item))
      ) return
    dispatch({ type: 'restoreWarenkorbItems', payload: warenkorb.items })
    if (hasOwnProperty(warenkorb, 'coupon')) {
      dispatch({ type: 'applyCoupon', payload: warenkorb.coupon })
    }
  }

  const loadKasse = () => {
    const storedKasse = localStorage.getItem('kasse')
    if (!storedKasse) return
    const kasse:unknown = JSON.parse(storedKasse)
    if (!isStorageKasse(kasse)) return
    dispatch({ type: 'restoreKasse', payload: kasse })
  }

  const loadWunschliste = () => {
    const storedWunschliste = localStorage.getItem('wunschliste')
    if (!storedWunschliste) return
    const wunschliste:unknown = JSON.parse(storedWunschliste)
    if (
      !wunschliste
      || typeof wunschliste !== 'object'
      || !hasOwnProperty(wunschliste, 'items')
      || !isWunschlisteItems(wunschliste.items)
      || !Object.values(wunschliste.items).every(item=>isWunschlisteItem(item))
      ) return
    dispatch({ type: 'restoreWunschlisteItems', payload: wunschliste.items })
  }

  const loadAll = () => {
    loadUser()
    loadWarenkorb()
    loadKasse()
    loadWunschliste()
  }

  const onFocus = () => loadAll()

  useEffect(() => {
    loadAll()
    window.addEventListener('focus', onFocus, { passive: true })

    return () => window.removeEventListener('focus', onFocus)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default CheckLocalStorage

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
