import { ThemeOptions } from '@mui/material'

export const font = 'Lato'
export const mode: 'light' | 'dark' = 'light'

export const theme: ThemeOptions = {
  palette: {
    mode,

    primary: {
      main: '#ba0063',
    },
    secondary: {
      main: '#eab3d0',
    },
    tertiary: {
      main: '#a30047',
    },
    info: {
      main: '#2196F3',
    },
    success: {
      main: '#21923A',
    },
    warning: {
      main: '#E48C19',
    },
    error: {
      main: '#CF5A56',
    },
    background: {
      default: '#ffffff',
    },

    /*text: {
      primary: '#4D4D4D',
    },*/
  },

  typography: {
    fontFamily: [`"${font}"`, 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
}
